<template>
  <main>
    <div class="page-content">
      <div>{{ errorNews }}</div>
      <div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
          <div v-for="post in news" :key="post.id" class="col">
            <PostSingle :post="post" />
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="news" />
</template>

<script>
import PostSingle from "@/components/posts/PostSingle.vue";
import Footer from "@/components/navigation/Footer.vue";
import getCollection from "@/composables/getCollection";
import { ref } from "@vue/reactivity";

export default {
  components: { PostSingle, Footer },
  setup() {
    const articleToShow = ref(0);
    const { error: errorNews, documents: news } = getCollection(
      "rhNews",
      [
        ["order", ">", 0],
        ["showOnNewsPage", "==", true]
      ],
      ["order", "asc"]
    );

    const handleShowArticle = articleId => {
      if (articleToShow.value == articleId) {
        articleToShow.value = 0;
      } else {
        articleToShow.value = articleId;
      }
      document.getElementById(articleId).scrollTo();
    };

    return { errorNews, news, articleToShow, handleShowArticle };
  }
};
</script>

<style scoped>
.story-text {
  /* white-space: pre-wrap; */
  text-align: left;
}
</style>

