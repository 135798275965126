<template>
  <router-link
    class="offering-link"
    :to="{ name: 'Post', params: { id: post.id } }"
  >
    <div class="card shadow-sm  post-single">
      <img
        v-if="post.imageURLMedium"
        :src="post.imageURLMedium"
        class="bd-placeholder-img card-img-top"
        height="200"
        width="100%"
      />
      <div class="card-body">
        <h3 class="card-title">{{ post.headline }}</h3>
        <p
          v-html="post.story.substring(0, 300) + '<b>...(more)</b>'"
          class="card-text"
        ></p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["post"]
};
</script>


<style>
</style>